import { decodeJwt } from 'lib/utils';

export const getUserEmail = () => {
  const token = localStorage.getItem('authToken');

  if (!token) {
    return '';
  }

  return decodeJwt<{ email: string }>(token).email;
};

export const getUserRoles = () => {
  if (typeof window !== 'undefined') {
    const token = localStorage.getItem('authToken');

    if (!token) {
      return [];
    }

    return decodeJwt<{ roles?: string[] }>(token.toString()).roles || [];
  }

  return [];
};
